import {
    Button,
    Col, Input, message, Row, Table,
    Space
} from 'antd';
import axios from 'axios';
import Can from '../../components/Can';
import FilterGenerateSPCard from './components/FilterGenerateSPCard';
import React, { useEffect, useState } from 'react';
import { url } from '../../constant/url';
import {
    ExportOutlined
} from '@ant-design/icons';
import fileDownload from 'js-file-download';
import moment from 'moment';
import Axios from '../../config/axios';

function Ng(history) {
    const columns = [
        {
            title: 'Scan Date',
            dataIndex: 'scan_time',
            key: 'scan_time',
            width: 150,
            render: (date) => {
                return moment(date).format('DD-MM-YYYY');
            },
            sorter: false,
        },

        {
            title: 'Department',
            dataIndex: 'plan_name',
            key: 'plan_name',
        },
        {
            title: 'Main Process Name',
            dataIndex: 'main_process',
            key: 'main_process',
            sorter: false,
            width: 200,
            render: (main_process) => (main_process ? main_process : '-'),
        },
        {
            title: 'Sub Process Name',
            dataIndex: 'sub_process_name',
            key: 'sub_process_name',
            sorter: false,
            width: 200,
            render: (sub_process_name) =>
                sub_process_name ? sub_process_name : '-',
        },

        {
            title: 'Line Process Name',
            dataIndex: 'line_process_name',
            key: 'line_process_name',
            sorter: false,
            render: (line_process_name) =>
                line_process_name ? line_process_name : '-',
        },
        {
            title: 'Part Number',
            dataIndex: 'part_number',
            key: 'part_number',
            width: 200,
            render: (part_number) => (part_number ? part_number : '-'),
        },

        {
            title: 'Part Name',
            dataIndex: 'part_name',
            key: 'part_name',
            width: 250,
            render: (part_name) => (part_name ? part_name : '-'),
        },

        {
            title: 'Qty SPCARD',
            dataIndex: 'qty_spcard',
            key: 'qty_spcard',
            sorter: false,
        },
        {
            title: 'Qty ERP (SNP)',
            dataIndex: 'qty_erp',
            key: 'qty_erp',
            sorter: false,
            render: (qty_erp) => (qty_erp ? qty_erp : '0'),
        },
        {
            title: 'Qty Ok',
            dataIndex: 'qty_ok',
            key: 'qty_ok',
            sorter: false,
            render: (qty_ok) => (qty_ok ? qty_ok : '0'),
        },
        {
            title: 'Qty Ng',
            dataIndex: 'qty_ng',
            key: 'qty_ng',
            sorter: false,
            render: (qty_ng) => (qty_ng ? qty_ng : '0'),
        },
        {
            title: 'Qty Current',
            dataIndex: 'qty_current',
            key: 'qty_current',
            sorter: false,
            render: (qty_current) => (qty_current ? qty_current : '0'),
        },
        {
            title: 'Qty Added',
            dataIndex: 'additional_qty',
            key: 'additional_qty',
            sorter: false,
            render: (additional_qty) => (additional_qty ? additional_qty : '0'),
        },
        {
            title: 'Shift',
            dataIndex: 'shift',
            key: 'shift',
            sorter: false,
            width: 100,
        },

        {
            title: 'CT Mesin',
            dataIndex: 'cycle_time',
            key: 'cycle_time',
            sorter: false,
            render: (cycle_time) => (cycle_time ? cycle_time : '0'),
        },
        {
            title: 'CT Proses',
            dataIndex: 'cycle_time_process',
            key: 'cycle_time_process',
            sorter: false,
            render: (cycle_time_process) => (cycle_time_process ? cycle_time_process : '0'),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: false,
        },
        {
            title: 'OT Durasi',
            dataIndex: 'ot_second',
            key: 'ot_second',
            sorter: false,
            render: (ot_second) => (ot_second ? ot_second : '0'),
        },

        {
            title: 'Scan By',
            dataIndex: 'scan_by_name',
            key: 'scan_by_name',
            sorter: false,
        },
        {
            title: 'Scan Time',
            dataIndex: 'scan_time',
            key: 'scan_time',
            sorter: false,
            width: 200,
        },
        {
            title: 'QRCODE CUT',
            dataIndex: 'qrcode',
            key: 'qrcode',
            sorter: false,
        },
        {
            title: 'Lot No Pipa',
            dataIndex: 'lot_number',
            key: 'lot_number',
            width: 300,
            sorter: false,
        },
        {
            title: 'QRCODE DEB',
            dataIndex: 'qrcode_internal',
            key: 'qrcode_internal',
            sorter: false,
            width: 500,
            render: (qrcode_internal) =>
                qrcode_internal ? qrcode_internal : '-',
        },
        {
            title: 'QRCODE ERP',
            dataIndex: 'qrcode_erp',
            key: 'qrcode_erp',
            sorter: false,
            render: (qrcode_erp) => (qrcode_erp ? qrcode_erp : '-'),
        },
        {
            title: 'QRcode Material',
            dataIndex: 'qrcode_material',
            key: 'qrcode_material',
            sorter: false,
            width: 400,
        },
        {
            title: 'Additional From',
            dataIndex: 'additional_from',
            key: 'additional_from',
            sorter: false,
        },
        {
            title: 'Qrcode Merge Assy',
            dataIndex: 'assy_merge',
            key: 'assy_merge',
            sorter: false,
            width: 500
        },
        {
            title: 'Year',
            dataIndex: 'scan_time',
            key: 'year',
            render: (date) => {
                return moment(date).format('YYYY');
            },
            sorter: false,
        },
        {
            title: 'Month',
            dataIndex: 'scan_time',
            key: 'month',
            sorter: false,
            render: (date) => {
                return moment(date).format('MM');
            },
        },
        {
            title: 'Date',
            dataIndex: 'scan_time',
            key: 'date2',
            sorter: false,
            render: (date) => {
                return moment(date).format('DD');
            },
        },
        {
            title: 'Loading MC',
            dataIndex: 'loading_mc',
            key: 'loading_mc',
            sorter: false,
        },
        {
            title: 'Loading MP',
            dataIndex: 'loading_mp',
            key: 'loading_mp',
            sorter: false,
        },
        {
            title: 'EFF % MC',
            dataIndex: 'eff_mc',
            key: 'eff_mc',
            sorter: false,
        },
        {
            title: 'EFF % MP',
            dataIndex: 'eff_mp',
            key: 'eff_mp',
            sorter: false,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: false,
            width: 500
        },
    ];

    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'descend',
    });

    const [loading, setLoading] = useState(false);

    const [keyword, setKeyword] = useState('');

    const [operator, setOperator] = useState([]);

    const [data, setData] = useState({
        file: null,
    });

    const [filters, setFilters] = useState({});
    const handleRemove = () => {
        setData({
            ...data,
            file: null,
        });
    };

    useEffect(() => {
        getData();
    }, [keyword, page, perpage, sort, filters]);

    const getData = (newFilters = null) => {
        setLoading(true);
        const appliedFilters = newFilters !== null ? newFilters : filters;

        Axios
            .get(`${url}/report-traceability`, {
                params: {
                    keyword,
                    filters: appliedFilters,
                    columns: [
                        'qrcode',
                        'part_number',
                        'part_name',
                        'plan_name',
                        'line_process_name',
                        'main_process',
                        'sub_process_name',
                        'scan_time',
                        'qty_spcard',
                        'qty_internal',
                        'qty_erp',
                        'qty_ok',
                        'qty_ng',
                        'shift',
                        'ct_mesin',
                        'ct_proses',
                        'status',
                        'ot_minute',
                        'scan_by_name',
                        'lot_no',
                        'qrcode_internal',
                        'scan_time',
                        'ot_second',
                        'scan_by',
                        'qrcode_material',
                        'month',
                        'date2',
                        'loading_mc',
                        'loading_mp',
                        'efficiency_mc',
                        'efficiency_mp',
                        'cycle_time',
                        'cycle_time_process',
                        'qrcode_erp',
                        'lot_number',
                        'created_at',
                        'updated_at',
                    ],
                    perpage: perpage,
                    page,
                    sort,
                },
            })
            .then((res) => {
                setOperator(res.data);
                setTotal(res.data.total);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        if (!Array.isArray(sorter)) {
            setSort([sorter]);
        } else {
            setSort(sorter);
        }
    };

    const downloadTemplate = (exportFilters = null) => {
        setLoading(true);
        const appliedFilters = exportFilters !== null ? exportFilters : filters;
        axios
            .get(`${url}/report-traceability/export`, {
                params: {
                    keyword,
                    columns: [
                        'qrcode',
                        'part_number',
                        'part_name',
                        'plan_name',
                        'line_process_name',
                        'main_process',
                        'sub_process_name',
                        'scan_time',
                        'qty_spcard',
                        'qty_internal',
                        'qty_erp',
                        'qty_ok',
                        'qty_ng',
                        'shift',
                        'ct_mesin',
                        'ct_proses',
                        'status',
                        'ot_minute',
                        'scan_by_name',
                        'lot_no',
                        'qrcode_internal',
                        'scan_time',
                        'ot_second',
                        'scan_by',
                        'qrcode_material',
                        'month',
                        'date2',
                        'loading_mc',
                        'loading_mp',
                        'efficiency_mc',
                        'efficiency_mp',
                        'cycle_time',
                        'cycle_time_process',
                        'qrcode_erp',
                        'lot_number',
                        'created_at',
                        'updated_at',
                    ],
                   
                    filters: appliedFilters,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                responseType: 'blob',
            })
            .then((res) => {
                setLoading(false);
                fileDownload(res.data, `Export Report Traceability.xlsx`);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            });
    };

    return (
        <div>
            <ul className="breadcumb">
                <li className="active">Report Traceability</li>
            </ul>
            <div className="content">
                <Row justify="space-between">
                    <Col>
                        <Can accessTo="Export Report Traceability">
                            <Button
                            loading={loading}
                                onClick={() => downloadTemplate(filters)}
                                type="primary"
                                style={{
                                    backgroundColor: '#1b2086',
                                    borderColor: '#1b2086',
                                    marginLeft: 10,
                                }}
                            >
                                <ExportOutlined />
                                Export
                            </Button>
                        </Can>
                    </Col>
                    <Col>
                        <Space>
                            <FilterGenerateSPCard onFinish={setFilters} />
                            <Input.Search
                                allowClear="true"
                                onSearch={(value) => {
                                    setPage(1);
                                    setKeyword(value);
                                }}
                                placeholder="Search..."
                                style={{width: 500}}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row className="mt-40">
                    <Col xs={24}>
                        <Table
                            scroll={{ x: "max-content" }}
                            columns={columns}
                            dataSource={operator.data}
                            pagination={{
                                current: page,
                                total,
                                showSizeChanger: true,
                            }}
                            loading={loading}
                            rowKey={(data) => data._id}
                            onChange={handleTableChange}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Ng;
